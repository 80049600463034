<template>
  <div v-if="loading">
    <v-skeleton-loader v-for="i in 3" :key="i" type="list-item-avatar-three-line" />
  </div>
  <v-card v-else class="elevation-x">
    <v-list v-if="memberships.length" three-line>
      <template v-if="selectable">
        <v-list-item-group>
          <template v-for="(membership, index) in memberships">
            <user-list-item :key="membership.uid"
                            :user="person(membership.user)"
                            @click="() => toggleOwner(membership)"
            />
            <v-divider v-if="index + 1 < memberships.length" :key="'divider- '+ membership.uid"
                       class="my-0"
            />
          </template>
        </v-list-item-group>
      </template>
      <template v-else>
        <v-list-item v-for="(membership, index) in memberships" :key="membership.uid" :value="membership">
          <user-list-item :user="person(membership.user)" go-to-profile>
            <template v-slot:actions>
              <v-tooltip v-if="(membership.user !== $auth.user.sub && $can('remove_member', team))" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn :loading="removeMemberLoading" icon v-on="on" @click.stop="removeMember(membership)">
                    <v-icon color="error">$close</v-icon>
                  </v-btn>
                </template>
                <span>Retirer du groupe</span>
              </v-tooltip>
            </template>
          </user-list-item>
          <v-divider v-if="index + 1 < memberships.length" :key="'divider- '+ membership.uid" class="my-0" />
        </v-list-item>
      </template>
    </v-list>
    <div v-else class="px-4 pb-3">
      <div class="text-caption">Aucun membre dans ce groupe</div>
    </div>
  </v-card>
</template>

<script>
  import UserListItem from '@/modules/core/layout/UserListItem'
  import {Membership, Person, Team} from '@/models'
  import viewProfile from '@/mixins/navigationMethods'

  export default {
    name: 'TeamMembersList',
    components: {UserListItem},
    mixins: [viewProfile],
    props: {
      value: Object,
      team: Team,
      hideMe: Boolean,
      selectable: Boolean
    },
    data() {
      return {
        loading: true,
        removeMemberLoading: false
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      memberships() {
        let memberships = []
        if (this.team.memberships) {
          memberships = this.team.memberships
          if (this.hideMe && this.team.membership) {
            memberships = memberships.filter(membership => membership !== this.team.membership)
          }
          memberships = memberships.map(uid => Membership.get(uid))
        }
        return memberships
      },
      person() {
        return uid => Person.get(uid)
      }
    },
    async mounted() {
      await this.team.fetchMemberships()
      this.loading = false
    },
    methods: {
      toggleOwner(membership) {
        this.localValue = membership
      },
      async removeMember(membership) {
        try {
          this.removeMemberLoading = true
          await this.team.removeMember(membership)

          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Membre retiré',
            subtitle: 'Membre retiré du groupe avec succès.'
          })
        } catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'Le membre n\'a pas pu être retiré du groupe.'
          })
          throw e
        } finally {
          this.removeMemberLoading = false
        }
      }
    }
  }
</script>
